.parameters :global {
  label {
    font-weight: bold;
    display: block;
  }

  --text: #646b8c;
  --text-placeholder: #bbc1e1;
  --icon: #a6accd;
  --icon-focus: #646b8c;
  --icon-invalid: #f04949;
  --icon-valid: #16bf78;
  --background: #fff;
  --border: #d1d6ee;
  --border-hover: #a6accd;
  --border-focus: #275efe;
  --shadow-focus: #{rgba(#275efe, 0.32)};
  input {
    margin-top: 0.25rem;
    padding: 0.25rem 0.5rem;
    width: 100%;
    -webkit-appearance: none;
    outline: none;
    display: block;
    font-size: 1rem;
    font-family: inherit;
    line-height: 26px;
    border-radius: 6px;
    color: var(--text);
    border: 1px solid var(--bc, var(--border));
    background: var(--background);
    transition: border-color 0.3s, box-shadow 0.3s;
    &::placeholder {
      color: var(--text-placeholder);
    }
    &:hover {
      --bc: var(--border-hover);
    }
    &:focus-within {
      --bc: var(--border-focus);
      --i: var(--icon-focus);
      input {
        box-shadow: 0 1px 6px -1px var(--shadow-focus);
      }
    }
  }
}
