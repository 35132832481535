.peer-evaluations :global {
  h1 {
    font-size: 200%;
  }

  form {
    .input-group {
      label {
        display: block;
        margin-bottom: 0.5rem;
      }

      input {
        display: block;
      }
    }
  }
}
