.evaluatees :global {
  ul {
    margin-top: 2rem;
  }

  li {
    margin-bottom: 2rem;
  }

  button {
    display: inline-flex;
    gap: 0.5rem;

    background-color: rgba(51, 51, 51, 0.05);
    border-radius: 8px;
    color: #333333;
    cursor: pointer;
    margin: 0;
    padding: 0.5rem 1rem;
    text-align: center;
    transition: all 200ms;
    white-space: nowrap;

    &:disabled {
      cursor: not-allowed;
      color: #666;
      background-color: rgba(51, 51, 51, 0.2);
    }
  }
}
