body {
  font-size: 16px;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1 {
  font-size: 200%;
  margin-bottom: 1rem;
}
h2 {
  font-size: 175%;
  margin-bottom: 1rem;
}
h3 {
  font-size: 150%;
  margin-bottom: 1rem;
}

table {
  border-collapse: collapse;

  th,
  td {
    padding: 0.25rem;
    border: 1px solid #ccc;
  }
}

ul {
  list-style: none;
}
